import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './home';
import About from './About';
import Blog from './Blog';
import Sale from './Sale'; 
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        {/* Navbar */}
        <nav className="navbar">
          <h1>Arian Kazemi</h1>
          <ul className="nav-links">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/blog">Blog Posts</Link></li>
            <li><Link to="/Sale">Sale</Link></li>
          </ul>
        </nav>


        {/* Define Routes */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/Sale" element={<Sale />} /> {/* New route for SPO600 */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
