import React from 'react';
import './App.css';
import { Typewriter } from 'react-simple-typewriter';

function Home() {
  return (
    <div className="home">
      {/* Hero Section */}
      <section className="hero">
        <h1>Hi, I'm <span className="highlight">Arian Kazemi</span></h1>
        <h2>
          <Typewriter
            words={['Software Developer', 'Tech Enthusiast', 'Open-Source Contributor']}
            loop={true}
            cursor
            cursorStyle="|"
            typeSpeed={70}
            deleteSpeed={50}
            delaySpeed={1000}
          />
        </h2>
        <p>Passionate about Web Development, Game Programming, and AI.</p>
        <a href="/about" className="cta-button">Learn More</a>
      </section>

      {/* Skills & Key Courses Section */}
      <section className="skills">
        <h2>Technical Skills & Key Courses</h2>
        <div className="skills-container">
          <div className="skill">🔥 JavaScript</div>
          <div className="skill">⚛️ React & Web Development</div>
          <div className="skill">🐍 Python & Data Analytics</div>
          <div className="skill">🎮 C++ & OpenGL Game Dev</div>
          <div className="skill">💾 Database Systems (SQL, Firebase)</div>
          <div className="skill">📊 Data Structures & Algorithms</div>
          <div className="skill">🛠️ Software Testing & Debugging</div>
          <div className="skill">🔧 IT Project Management</div>
        </div>
      </section>

      {/* Featured Projects */}
      <section className="projects">
        <h2>Featured Projects</h2>
        <div className="project-card">
          <h3>💻 Portfolio Website</h3>
          <p>Built with React & Firebase, showcasing my skills and projects.</p>
          <a href="https://github.com/Arian-Kazemi" target="_blank" rel="noopener noreferrer">View on GitHub</a>
        </div>
        <div className="project-card">
          <h3>🎮 OpenGL Game Engine</h3>
          <p>Created a 2D game engine using C++ & OpenGL.</p>
          <a href="https://github.com/Arian-Kazemi" target="_blank" rel="noopener noreferrer">View on GitHub</a>
        </div>
      </section>

      {/* Contact Section */}
      <section className="contact">
        <h2>Let's Connect</h2>
        <p>Have a project idea or want to collaborate? Let's talk!</p>
        <a href="mailto:your-email@example.com" className="cta-button">Contact Me</a>
      </section>
    </div>
  );
}

export default Home;
