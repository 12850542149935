import React, { useState } from 'react';
import './Sale.css'; // Ensure you create this file for styling

function Sale() {
  const [isSoldOut, setIsSoldOut] = useState(false);

  const handleAddToCart = () => {
    setIsSoldOut(true);
  };

  return (
    <div className="sale">
      <h2>Exclusive Product for Sale</h2>

      {/* Product Card */}
      <div className="product-card">
        <img 
          src="/Arian.jpg" 
          alt="Product" 
          className="product-image"
        />
        <h3>!</h3>
        <p>Hf.</p>

        {/* Add to Cart Button */}
        <button 
          onClick={handleAddToCart} 
          className={isSoldOut ? "sold-out" : "add-to-cart"}
          disabled={isSoldOut}
        >
          {isSoldOut ? "Sold Out, Come Back Again" : "Add to Cart"}
        </button>
      </div>
    </div>
  );
}

export default Sale;
