import React from 'react';
import './App.css'; // Assuming the styles are in App.css

function About() {
  return (
    <div className="about">
      <h2>About Me</h2>
      {/* Reference the image from the public folder */}
      <img src="/Arian.jpg" alt="Arian Kazemi" className="profile-photo" />
      <p>
        Hi, I'm Arian Kazemi, a passionate web developer with a love for technology and open-source projects. 
        Through this blog, I share my thoughts, experiences, and insights on the latest in web development, coding, 
        and the open-source community.
      </p>
      <h3>Resume</h3>
      <p>
        You can download my resume <a href="/Resume Arian Kazemi (1).pdf" download>here</a>.
      </p>
    </div>
  );
}

export default About;
